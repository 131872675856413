import { Col, Form, Input, Modal, Progress, Row, Select, Table, Tooltip, notification } from 'antd';
import { useEffect, useRef, useState } from 'react';
import React from "react";

import axios from 'axios';
import Swal from "sweetalert2";
import IconUpload from "../../images/Upload.svg";
import { Button, IconButton } from '@mui/material';
import "./NewKado.css"
import withReactContent from 'sweetalert2-react-content';
import FileTemplate from "../../file/Template.xlsx"
import dayjs from 'dayjs';
import Logo from "../../images/VBPO_Logo.png";
import { CloudDownloadOutlined, DownloadOutlined, SmileOutlined, UploadOutlined } from '@ant-design/icons';

// const localhost = "http://192.168.1.7:6060/"
// const localhost = "http://192.168.20.162:6061/"
const localhost = "https://kado-test.dominatech.xyz/"

const { Option } = Select;
const { Search } = Input;

const checkScreen800 = window.screen.height < 800

const buttonUploadFile = {
    display: "flex",
    alignItems: "center",
    color: "#3ab7b0",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    border: "none",
    borderRadius: "8px",
    padding: 0,
    textTransform: "none",
    // height: 48,
    marginRight: 16,
    // background: "#fff",
    minWidth: "auto",
};

const buttonUploadFileLapTop = {
    display: "flex",
    alignItems: "center",
    color: "#3ab7b0",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    border: "none",
    borderRadius: "8px",
    padding: 0,
    textTransform: "none",
    // height: 40,
    marginRight: 16,
    // background: "#fff",
    minWidth: "auto",
};

const ToastSuccess = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
    customClass: {
        popup: "my-custom-popup",
        timerProgressBar: "my-custom-progress-bar-success", // Thêm class tùy chỉnh
        icon: 'custom-icon-class',
        container: 'custom-modal-background',
    },
});
const NewKado = React.memo(() => {
    const fileInputRef = useRef(null);
    const [valueNameNCC, setValueNameNCC] = useState(null);
    const [isOpenProgress, setIsOpenProgress] = useState(false);
    const [percentTest, setPercentTest] = useState(0);
    const [fileImport, setFileImport] = useState()
    const [dataTable, setDataTable] = useState([])

    const [countTrueKanji1, setCounTrueKanji1] = useState(0)
    const [countTrueKanji2, setCounTrueKanji2] = useState(0)
    const [countTrueKanji3, setCounTrueKanji3] = useState(0)
    const [countTrueKana12, setCounTrueKana12] = useState(0)
    const [countTrueKana3, setCounTrueKana3] = useState(0)
    const [totalTrue, setTotalTrue] = useState(0)
    const [totalFalse, setTotalFalse] = useState(0)
    const [totalWarning, setTotalWarning] = useState(0)
    const [total, setTotal] = useState(0)
    const [statusData, setStatusData] = useState("")
    const [indexHighlight, setIndexHighlight] = useState(0)
    const [totalKanji, setTotalKanji] = useState(0)
    const [totalKana, setTotalKana] = useState(0)

    const [countOnHold, setCountOnHold] = useState(0)
    const [percentCountOnHold, setPercentCountOnHold] = useState(0)
    const [countReturn, setCountReturn] = useState(0)
    const [percentCountReturn, setPercentCountReturn] = useState(0)

    const [totalAccuracyKanji, setTotalAccuracyKanji] = useState(0)
    const [percentTotalAccuracyKanji, setPercentTotalAccuracyKanji] = useState(0)

    const [totalAccuracyKana, setTotalAccuracyKana] = useState(0)
    const [percentTotalAccuracyKana, setPercentTotalAccuracyKana] = useState(0)

    const [totalAccuracyAll, setTotalAccuracyAll] = useState(0)
    const [percentTotalAccuracyAll, setPercentTotalAccuracyAll] = useState(0)

    const [loadingButton, setLoadingButton] = useState(false)
    const [time, setTime] = useState(dayjs().format("YYYYMMDD_HHmmssSSS"))
    const [percentProcessing, setPercentProcessing] = useState(0)
    const [subID, setSubID] = useState(0)
    const [dataFilter, setDataFilter] = useState([])
    const [saveNameOption, setSaveNameOption] = useState()
    const [saveBoolOption, setSaveBoolOption] = useState()

    const [timeHandleAPI, setTimeHandleAPI] = useState(0)

    // data View
    const [viewFurigana, setViewFurigana] = useState()
    const [viewKanji, setViewKanji] = useState()

    const [dataBox1, setDataBox1] = useState()
    const [dataBox2, setDataBox2] = useState()
    const [dataBox3, setDataBox3] = useState()
    const [dataBox4, setDataBox4] = useState()
    const [dataBox5, setDataBox5] = useState()
    const [dataBox6, setDataBox6] = useState()
    const [dataBox7, setDataBox7] = useState()
    const [dataBox8, setDataBox8] = useState()
    const [dataBox9, setDataBox9] = useState()
    const [dataBox10, setDataBox10] = useState()
    const [dataBox11, setDataBox11] = useState()
    const [dataBox12, setDataBox12] = useState()
    const [dataBox13, setDataBox13] = useState()
    const [dataBox14, setDataBox14] = useState()
    const [dataBox15, setDataBox15] = useState()
    const [dataBox16, setDataBox16] = useState()
    const [dataBox17, setDataBox17] = useState()
    const [dataBox18, setDataBox18] = useState()
    const [dataBox19, setDataBox19] = useState()
    const [dataBox20, setDataBox20] = useState()
    const [dataBox21, setDataBox21] = useState()
    const [dataBox22, setDataBox22] = useState()
    const [dataBox23, setDataBox23] = useState()


    const [colorText5_11, setColorText5_11] = useState("black")
    const [colorText7_12, setColorText7_12] = useState("black")
    const [colorText9_13, setColorText9_13] = useState("black")
    const [colorText14_17, setColorText14_17] = useState("black")
    const [colorText15_19, setColorText15_19] = useState("black")
    const [colorText16_21, setColorText16_21] = useState("black")

    // Kanji1
    const [percentTrueKJ1, setPercentTrueKJ1] = useState(0)
    const [percentFalseKJ1, setPercentFalseKJ1] = useState(0)

    // Kanji2
    const [percentTrueKJ2, setPercentTrueKJ2] = useState(0)
    const [percentFalseKJ2, setPercentFalseKJ2] = useState(0)

    // Kanji3
    const [percentTrueKJ3, setPercentTrueKJ3] = useState(0)
    const [percentFalseKJ3, setPercentFalseKJ3] = useState(0)

    // Furi 12
    const [percentTrueFuri12, setPercentTrueFuri12] = useState(0)
    const [percentFalseFuri12, setPercentFalseFuri12] = useState(0)

    // Furi 3
    const [percentTrueFuri3, setPercentTrueFuri3] = useState(0)
    const [percentFalseFuri3, setPercentFalseFuri3] = useState(0)

    const [percentTotalTrue, setPercentTotalTrue] = useState(0)
    const [percentTotalFalse, setPercentTotalFalse] = useState(0)
    const [percentTotalWarning, setPercentTotalWarning] = useState(0)

    const [percentTrueTotalKJ, setPercentTotalTrueKJ] = useState(0)
    const [percentFalseTotalKJ, setPercentTotalFalseKJ] = useState(0)

    const [percentTrueTotalKN, setPercentTotalTrueKN] = useState(0)
    const [percentFalseTotalKN, setPercentTotalFalseKN] = useState(0)

    const [timeImport, setTimeImport] = useState()

    const [checkSuccessAPI, setCheckSuccessAPI] = useState(false)

    const [statusTotal, setStatusTotal] = useState('')
    const [nameTotal, setNameTotal] = useState('')


    const [statusOnHold, setStatusOnHold] = useState('')
    const [countFalseKanji, setCountFalseKanji] = useState(0)
    const [countFalseKana, setCountFalseKana] = useState(0)

    // const [colorTrueKJ1, setColorTrueKJ1] = useState("blue")
    // const [colorTrueKJ2, setColorTrueKJ2] = useState("blue")
    // const [colorTrueKJ3, setColorTrueKJ3] = useState("blue")
    // const [colorTrueFuri12, setColorTrueFuri12] = useState("blue")
    // const [colorTrueKFuri3, setColorTrueFuri3] = useState("blue")

    // const [colorFalseKJ1, setColorFalseKJ1] = useState("red")
    // const [colorFalseKJ2, setColorFalseKJ2] = useState("red")
    // const [colorFalseKJ3, setColorFalseKJ3] = useState("red")
    // const [colorFalseFuri12, setColorFalseFuri12] = useState("red")
    // const [colorFalseKFuri3, setColorFalseFuri3] = useState("red")

    const screenWindown1540px = window.screen.width === 1540;
    const isXlsxFile = (file) => {
        if (file !== undefined) {
            const fileType = file.type;
            const validTypes = [
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                // Add more valid xlsx MIME types here if needed
                "application/vnd.ms-excel",
            ];
            return validTypes.includes(fileType);
        }
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // Handle the uploaded file here
        if (!isXlsxFile(file)) {
            // openNotificationWithIcon(
            //   "error",
            //   "Upload không thành công!",
            //   "Vui lòng chọn file .xlsx hoặc .xls"
            // );
            return;
        } else {
            setValueNameNCC(file.name);
        }
    };

    useEffect(() => {
        if (percentProcessing === 100) {
            clearInterval(subID)
        }
    }, [percentProcessing]);


    const onStart = (values) => {
        var FormData = require("form-data");
        setLoadingButton(true)
        var data = new FormData();
        let newTime = dayjs().format("YYYYMMDD_HHmmssSSS")
        data.append("file_import", fileInputRef.current.files[0]);
        data.append("time_import", newTime);
        // currentDuAn
        var startTime = new Date().getTime();
        setCheckSuccessAPI(false)

        if (valueNameNCC !== null) {
            const id = setInterval(() => {
                getProcessing(newTime)
            }, 1000);

            const time = setTimeout(() => {
                setIsOpenProgress(true)
            }, 200)

            setSubID(id)
            axios.post(`${localhost}api/import-address/`, data).then(res => {

                setStatusTotalAcc("")
                setStatusOnHold('')
                setStatusData("")
                setSaveNameOption('')
                setInputSearch("")
                setNameTotal("")

                setDataTable(res.data.data)
                setDataFilter(res.data.data)

                setCounTrueKanji1(Number(res.data.count_kanji1))
                setCounTrueKanji2(Number(res.data.count_kanji2))
                setCounTrueKanji3(Number(res.data.count_kanji3))
                setCounTrueKana12(Number(res.data.count_kana12))
                setCounTrueKana3(Number(res.data.count_kana3))

                setTotalTrue(Number(res.data.total_count_true))
                setTotalFalse(Number(res.data.total_count_false))
                setTotalWarning(Number(res.data.total_count_warning))
                setTotal(Number(res.data.total_count_all))

                setTotalKanji(Number(res.data.total_kanji))
                setTotalKana(Number(res.data.total_kana))

                setCountFalseKanji(Number(res.data.total_count_all) - Number(res.data.total_kanji) - Number(res.data.total_count_warning) - Number(res.data.count_onhold))
                setCountFalseKana(Number(res.data.total_count_all) - Number(res.data.total_kana) - Number(res.data.total_count_warning) - Number(res.data.count_onhold))

                setCountOnHold(Number(res.data.count_onhold))
                setCountReturn(Number(res.data.total_count_warning))

                onSetValue(
                    Number(res.data.count_kanji1),
                    Number(res.data.count_kanji2),
                    Number(res.data.count_kanji3),
                    Number(res.data.count_kana12),
                    Number(res.data.count_kana3),
                    Number(res.data.total_count_true),
                    Number(res.data.total_count_false),
                    Number(res.data.total_count_warning),
                    Number(res.data.total_count_all),
                    Number(res.data.total_kanji),
                    Number(res.data.total_kana),
                    Number(res.data.count_onhold),
                )

                setLoadingButton(false)
                var endTime = new Date().getTime();
                var elapsedTime = endTime - startTime;
                var time = (elapsedTime / 1000 / 60).toFixed(2)
                setTimeHandleAPI(time * 60)
                setCheckSuccessAPI(true)

                setTimeImport(newTime)
            }).catch(err => {
                setIsOpenProgress(false)
                clearTimeout(time)
                setLoadingButton(false)
                clearInterval(id)
                checkErrNotification(err.response.data.error)
                setPercentProcessing(0)
                setCheckSuccessAPI(true)
            })
        } else {
            setLoadingButton(false)
            setCheckSuccessAPI(true)
            checkWarningNotification("You have not imported the file")
        }
    }

    const onSetValue = (
        countTrueKanji1,
        countTrueKanji2,
        countTrueKanji3,
        countTrueKana12,
        countTrueKana3,
        totalTrue,
        totalFalse,
        totalWarning,
        total,
        totalKanji,
        totalKana,
        countOnHold) => {

        // True Kanji 1
        if (isNaN(countTrueKanji1 / total) === true) {
            setPercentTrueKJ1(0)
        } else {
            setPercentTrueKJ1(((countTrueKanji1 / total) * 100).toFixed(1))
        }

        // False Kanji 1
        if (isNaN((total - countTrueKanji1) / total) === true) {
            setPercentFalseKJ1(0)
        } else {
            setPercentFalseKJ1((((total - countTrueKanji1) / total) * 100).toFixed(1))
        }

        // True Kanji 2
        if (isNaN(countTrueKanji2 / total) === true) {
            setPercentTrueKJ2(0)
        } else {
            setPercentTrueKJ2(((countTrueKanji2 / total) * 100).toFixed(1))
        }

        // False Kanji 2
        if (isNaN((total - countTrueKanji2) / total) === true) {
            setPercentFalseKJ2(0)
        } else {
            setPercentFalseKJ2((((total - countTrueKanji2) / total) * 100).toFixed(1))
        }

        // True Kanji 3
        if (isNaN(countTrueKanji3 / total) === true) {
            setPercentTrueKJ3(0)
        } else {
            setPercentTrueKJ3(((countTrueKanji3 / total) * 100).toFixed(1))
        }

        // False Kanji 3
        if (isNaN((total - countTrueKanji3) / total) === true) {
            setPercentFalseKJ3(0)
        } else {
            setPercentFalseKJ3((((total - countTrueKanji3) / total) * 100).toFixed(1))
        }

        // False Furi 1 2
        if (isNaN(countTrueKana12 / total) === true) {
            setPercentTrueFuri12(0)
        } else {
            setPercentTrueFuri12(((countTrueKana12 / total) * 100).toFixed(1))
        }

        // False Furi 1 2
        if (isNaN((total - countTrueKana12) / total) === true) {
            setPercentFalseFuri12(0)
        } else {
            setPercentFalseFuri12((((total - countTrueKana12) / total) * 100).toFixed(1))
        }

        // True Furi 3
        if (isNaN(countTrueKana3 / total) === true) {
            setPercentTrueFuri3(0)
        } else {
            setPercentTrueFuri3(((countTrueKana3 / total) * 100).toFixed(1))
        }

        // False Furi 3
        if (isNaN((total - countTrueKana3) / total) === true) {
            setPercentFalseFuri3(0)
        } else {
            setPercentFalseFuri3((((total - countTrueKana3) / total) * 100).toFixed(1))
        }

        // Total True
        if (isNaN(totalTrue / total) === true) {
            setPercentTotalTrue(0)
        } else {
            setPercentTotalTrue(((totalTrue / total) * 100).toFixed(1))
        }

        // Total False
        if (isNaN(totalFalse / total) === true) {
            setPercentTotalFalse(0)
        } else {
            setPercentTotalFalse(((totalFalse / total) * 100).toFixed(1))
        }

        // Total Warning
        if (isNaN(totalWarning / total) === true) {
            setPercentTotalWarning(0)
        } else {
            setPercentTotalWarning(((totalWarning / total) * 100).toFixed(1))
        }

        // Total True Kanji
        if (isNaN(totalKanji / total) === true) {
            setPercentTotalTrueKJ(0)
        } else {
            setPercentTotalTrueKJ(((totalKanji / total) * 100).toFixed(1))
        }

        // Total False Kanji
        if (isNaN((total - totalKanji - countOnHold - totalWarning) / total) === true) {
            setPercentTotalFalseKJ(0)
        } else {
            setPercentTotalFalseKJ((((total - totalKanji - countOnHold - totalWarning) / total) * 100).toFixed(1))
        }

        // Total True Kana
        if (isNaN(totalKana / total) === true) {
            setPercentTotalTrueKN(0)
        } else {
            setPercentTotalTrueKN(((totalKana / total) * 100).toFixed(1))
        }

        // Total False Kana
        if (isNaN((total - totalKana - countOnHold - totalWarning) / total) === true) {
            setPercentTotalFalseKN(0)
        } else {
            setPercentTotalFalseKN((((total - totalKana - countOnHold - totalWarning) / total) * 100).toFixed(1))
        }

        // Percent on hold
        if (isNaN(countOnHold / total) === true) {
            setPercentCountOnHold(0)
        } else {
            setPercentCountOnHold(((countOnHold / total) * 100).toFixed(1))
        }

        // count Total acc kanji
        let totalAccKanji = countOnHold + totalWarning + totalKanji
        setTotalAccuracyKanji(totalAccKanji)

        // percent Total acc kanji

        if (isNaN(totalAccKanji / total) === true) {
            setPercentTotalAccuracyKanji(0)
        } else {
            setPercentTotalAccuracyKanji(((totalAccKanji / total) * 100).toFixed(1))
        }


        // count Total acc kana
        let totalAccKana = countOnHold + totalWarning + totalKana
        setTotalAccuracyKana(countOnHold + totalWarning + totalKana)

        // percent Total acc kana
        if (isNaN(totalAccKana / total) === true) {
            setPercentTotalAccuracyKana(0)
        } else {
            setPercentTotalAccuracyKana(((totalAccKana / total) * 100).toFixed(1))
        }

        // count Total acc all
        let totalAccAll = countOnHold + totalWarning + totalTrue
        setTotalAccuracyAll(totalAccAll)
        // percent Total acc all
        if (isNaN(totalAccAll / total) === true) {
            setPercentTotalAccuracyAll(0)
        } else {
            setPercentTotalAccuracyAll(((totalAccAll / total) * 100).toFixed(1))
        }

    }

    const getProcessing = (time) => {
        var FormData = require("form-data");
        var data = new FormData();
        data.append("name_file_import", valueNameNCC);
        data.append("time_import", time);
        axios.get(`${localhost}api/import-address/`, {
            params: {
                name_file_import: valueNameNCC,
                time_import: time
            }
        }).then(res => {
            const number = res.data.processing.split("/");
            const progress = (
                (parseInt(number[0]) / parseInt(number[1])) *
                100
            ).toFixed(2);
            setPercentProcessing(Number(progress))
            // setDataTable(res.data.data)
        }).catch(err => {
            console.log(err.response.data.error)
        })
    }

    const columns = [
        {
            title: <p style={{ textAlign: "center", margin: 0 }}>ID</p>,
            dataIndex: 'STT',
            key: 'STT',
            align: 'center',
            width: "5%",
            render: (value) => (
                <span style={{ fontWeight: "bold" }}>{value}</span>
            )
        },
        {
            title: <p style={{ textAlign: "center", margin: 0 }}>ZIPCODE</p>,
            dataIndex: '01_MBD',
            key: '01_MBD',
            width: "6%",
        },
        {
            title: (<><div style={{ textAlign: "center" }}><span>KANJI_1_RESULT</span><br></br><span>KANJI_1_OUTPUT</span></div></>),
            dataIndex: 'KANJI_1_RESULT__KANJI_1_OUTPUT',
            key: 'KANJI_1_RESULT__KANJI_1_OUTPUT',
            render: (value, record) => (<>

                <Tooltip placement="top" title={value.replace(/－/g, "—")} overlayStyle={{ fontSize: 12 }}>
                    <p style={{ width: "100%", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: "ellipsis", color: record.IS_KANJI_1 === false ? "red" : "black" }}>
                        {value.replace(/－/g, "—")}
                    </p>
                </Tooltip>
            </>
            ),
        },
        {
            title: (<><div style={{ textAlign: "center" }}><span>KANJI_2_RESULT</span><br></br><span>KANJI_2_OUTPUT</span></div></>),
            dataIndex: 'KANJI_2_RESULT__KANJI_2_OUTPUT',
            key: 'KANJI_2_RESULT__KANJI_2_OUTPUT',
            render: (value, record) => (<>
                <Tooltip placement="top" title={value.replace(/－/g, "—")} overlayStyle={{ fontSize: 12 }}>
                    <p style={{ width: "100%", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: "ellipsis", color: record.IS_KANJI_2 === false ? "red" : "black" }}>
                        {value.replace(/－/g, "—")}
                    </p>
                </Tooltip>
            </>
            )
        },
        {
            title: (<><div style={{ textAlign: "center" }}><span>KANJI_3_RESULT</span><br></br><span>KANJI_3_OUTPUT</span></div></>),
            dataIndex: 'KANJI_3_RESULT__KANJI_3_OUTPUT',
            key: 'KANJI_3_RESULT__KANJI_3_OUTPUT',
            render: (value, record) => (<>
                <Tooltip placement="top" title={value.replace(/－/g, "—")} overlayStyle={{ fontSize: 12 }}>
                    <p style={{ width: "100%", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: "ellipsis", color: record.IS_KANJI_3 === false ? "red" : "black" }}>
                        {value.replace(/－/g, "—")}

                    </p>
                </Tooltip>
            </>
            )
        },
        {
            title: (<><div style={{ textAlign: "center" }}><span>FURI_1_2_RESULT</span><br></br><span>FURI_1_2_OUTPUT</span></div></>),
            dataIndex: 'FURI_1_2_RESULT__FURI_1_2_OUTPUT',
            key: 'FURI_1_2_RESULT__FURI_1_2_OUTPUT',
            render: (value, record) => (<>
                <Tooltip placement="top" title={value.replace(/－/g, "—")} overlayStyle={{ fontSize: 12 }}>
                    <p style={{ width: "100%", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: "ellipsis", color: record.IS_FURI_1_2 === false ? "red" : "black" }}>
                        {value.replace(/－/g, "—")}

                    </p>
                </Tooltip>
            </>
            )
        },

        {
            title: (<><div style={{ textAlign: "center" }}><span>FURI_3_RESULT</span><br></br><span>FURI_3_OUTPUT</span></div></>),
            dataIndex: 'FURI_3_OUTPUT__FURI_3_RESULT',
            key: 'FURI_3_OUTPUT__FURI_3_RESULT',
            render: (value, record) => (<>
                <Tooltip placement="top" title={value.replace(/－/g, "—")} overlayStyle={{ fontSize: 12 }}>
                    <p style={{ width: "100%", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: "ellipsis", color: record.IS_FURI_3 === false ? "red" : "black" }}>
                        {value.replace(/－/g, "—")}

                    </p>
                </Tooltip>
            </>
            )
        },
        {
            title: <p style={{ textAlign: "center", margin: 0 }}>RULES</p>,
            dataIndex: 'RULES',
            key: 'RULES',
            width: "8%",
            render: (value) => (
                <Tooltip placement="top" title={value} overlayStyle={{ fontSize: 12 }}>
                    <p style={{ width: "100%", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: "ellipsis" }}>
                        {value}
                    </p>
                </Tooltip>
            )
        },
    ];
    // const columns = [
    //     {
    //         title: <p style={{ textAlign: "center", margin: 0 }}>ID</p>,
    //         dataIndex: 'STT',
    //         key: 'STT',
    //         align: 'center',
    //         width: "5%",
    //         render: (value) => (
    //             <span style={{ fontWeight: "bold" }}>{value}</span>
    //         )
    //     },
    //     {
    //         title: <p style={{ textAlign: "center", margin: 0 }}>ZIPCODE</p>,
    //         dataIndex: '01_MBD',
    //         key: '01_MBD',
    //         width: "6%",
    //     },
    //     {
    //         title: <p style={{ textAlign: "center", margin: 0 }}>KANJI_1_RESULT</p>,
    //         dataIndex: 'KANJI_1_OUTPUT',
    //         key: 'KANJI_1_OUTPUT',
    //         render: (value, record) => (<>
    //             {console.log(record)}
    //             <Tooltip placement="top" title={value} overlayStyle={{ fontSize: 12 }}>
    //                 <p style={{ width: "100%", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: "ellipsis", color: record.IS_KANJI_1 === false ? "red" : "black" }}>
    //                     {value}
    //                 </p>
    //             </Tooltip>
    //         </>
    //         )
    //     },
    //     {
    //         title: <p style={{ textAlign: "center", margin: 0 }}>KANJI_1_RESULT</p>,
    //         dataIndex: 'KANJI_1_RESULT',
    //         key: 'KANJI_1_RESULT',
    //         render: (value, record) => (
    //             <Tooltip placement="top" title={value} overlayStyle={{ fontSize: 12 }}>
    //                 <p style={{ width: "100%", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: "ellipsis", color: record.IS_KANJI_1 === false ? "red" : "black" }}>
    //                     {value}
    //                 </p>
    //             </Tooltip>
    //         )
    //     },
    //     {
    //         title: <p style={{ textAlign: "center", margin: 0 }}>KANJI_2_OUTPUT</p>,
    //         dataIndex: 'KANJI_2_OUTPUT',
    //         key: 'KANJI_2_OUTPUT',
    //         render: (value, record) => (
    //             <Tooltip placement="top" title={value} overlayStyle={{ fontSize: 12 }}>
    //                 <p style={{ width: "100%", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: "ellipsis", color: record.IS_KANJI_2 === false ? "red" : "black" }}>
    //                     {value}
    //                 </p>
    //             </Tooltip>
    //         )
    //     },
    //     {
    //         title: <p style={{ textAlign: "center", margin: 0 }}>KANJI_2_RESULT</p>,
    //         dataIndex: 'KANJI_2_RESULT',
    //         key: 'KANJI_2_RESULT',
    //         render: (value, record) => (
    //             <Tooltip placement="top" title={value} overlayStyle={{ fontSize: 12 }}>
    //                 <p style={{ width: "100%", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: "ellipsis", color: record.IS_KANJI_2 === false ? "red" : "black" }}>
    //                     {value}
    //                 </p>
    //             </Tooltip>
    //         )
    //     },
    //     {
    //         title: <p style={{ textAlign: "center", margin: 0 }}>KANJI_3_OUTPUT</p>,
    //         dataIndex: 'KANJI_3_OUTPUT',
    //         key: 'KANJI_3_OUTPUT',
    //         render: (value, record) => (
    //             <Tooltip placement="top" title={value} overlayStyle={{ fontSize: 12 }}>
    //                 <p style={{ width: "100%", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: "ellipsis", color: record.IS_KANJI_3 === false ? "red" : "black" }}>
    //                     {value}
    //                 </p>
    //             </Tooltip>
    //         )
    //     },
    //     {
    //         title: <p style={{ textAlign: "center", margin: 0 }}>KANJI_3_RESULT</p>,
    //         dataIndex: 'KANJI_3_RESULT',
    //         key: 'KANJI_3_RESULT',
    //         render: (value, record) => (
    //             <Tooltip placement="top" title={value} overlayStyle={{ fontSize: 12 }}>
    //                 <p style={{ width: "100%", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: "ellipsis", color: record.IS_KANJI_3 === false ? "red" : "black" }}>
    //                     {value}
    //                 </p>
    //             </Tooltip>
    //         )
    //     },
    //     {
    //         title: <p style={{ textAlign: "center", margin: 0 }}>FURI_1_2_OUTPUT</p>,
    //         dataIndex: 'FURI_1_2_OUTPUT',
    //         key: 'FURI_1_2_OUTPUT',
    //         render: (value, record) => (
    //             <Tooltip placement="top" title={value} overlayStyle={{ fontSize: 12 }}>
    //                 <p style={{ width: "100%", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: "ellipsis", color: record.IS_FURI_1_2 === false ? "red" : "black" }}>
    //                     {value}
    //                 </p>
    //             </Tooltip>
    //         )
    //     },
    //     {
    //         title: <p style={{ textAlign: "center", margin: 0 }}>FURI_1_2_RESULT</p>,
    //         dataIndex: 'FURI_1_2_RESULT',
    //         key: 'FURI_1_2_RESULT',
    //         render: (value, record) => (
    //             <Tooltip placement="top" title={value} overlayStyle={{ fontSize: 12 }} >
    //                 <p style={{ width: "100%", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: "ellipsis", color: record.IS_FURI_1_2 === false ? "red" : "black" }}>
    //                     {value}
    //                 </p>
    //             </Tooltip>
    //         )
    //     },
    //     {
    //         title: <p style={{ textAlign: "center", margin: 0 }}>FURI_3_OUTPUT</p>,
    //         dataIndex: 'FURI_3_OUTPUT',
    //         key: 'FURI_3_OUTPUT',
    //         render: (value, record) => (
    //             <Tooltip placement="top" title={value} overlayStyle={{ fontSize: 12 }}>
    //                 <p style={{ width: "100%", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: "ellipsis", color: record.IS_FURI_3 === false ? "red" : "black" }}>
    //                     {value}
    //                 </p>
    //             </Tooltip>
    //         )
    //     },
    //     {
    //         title: <p style={{ textAlign: "center", margin: 0 }}>FURI_3_RESULT</p>,
    //         dataIndex: 'FURI_3_RESULT',
    //         key: 'FURI_3_RESULT',
    //         render: (value, record) => (
    //             <Tooltip placement="top" title={value} overlayStyle={{ fontSize: 12 }}>
    //                 <p style={{ width: "100%", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: "ellipsis", color: record.IS_FURI_3 === false ? "red" : "black" }}>
    //                     {value}
    //                 </p>
    //             </Tooltip>
    //         )
    //     },
    //     {
    //         title: <p style={{ textAlign: "center", margin: 0 }}>RULES</p>,
    //         dataIndex: 'RULES',
    //         key: 'RULES',
    //         width: "8%",
    //         render: (value) => (
    //             <Tooltip placement="top" title={value} overlayStyle={{ fontSize: 12 }}>
    //                 <p style={{ width: "100%", whiteSpace: "nowrap", overflow: 'hidden', textOverflow: "ellipsis" }}>
    //                     {value}
    //                 </p>
    //             </Tooltip>
    //         )
    //     },
    // ];

    const checkErrNotification = (message) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            icon: "error",
            html: <i style={{ color: "red" }}>{message}</i>,
            customClass: {
                icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
            },
            position: "center",
            confirmButtonColor: "#3ab7b0",
            confirmButtonText: "CONFIRM",
            width: "40%",
        });
    }
    const checkWarningNotification = (message) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            icon: "warning",
            html: <i style={{ color: "red" }}>{message}</i>,
            customClass: {
                icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
            },
            position: "center",
            confirmButtonColor: "#3ab7b0",
            confirmButtonText: "CONFIRM",
            width: "40%",
        });
    }

    const onFilterData = (name, bool) => {
        let newDataFilter = []
        if (saveNameOption !== undefined && saveBoolOption !== undefined) {
            if (name.toLowerCase() === saveNameOption.toLowerCase() && bool === saveBoolOption) {
                setSaveBoolOption()
                setSaveNameOption()
                setDataFilter(dataTable)
                onHandleViewData(dataTable[0], 0)
            } else {
                setStatusData("")
                setNameTotal("")
                setSaveNameOption(name.toLowerCase())
                setSaveBoolOption(bool)

                if (name.toLowerCase() === "kanji_1") {
                    if (bool !== true && bool !== false) {
                        newDataFilter = dataTable.filter(item => item.IS_KANJI_1 !== true && item.IS_KANJI_1 !== false)
                        setDataFilter(newDataFilter)
                        onHandleViewData(newDataFilter[0], 0)

                    } else {
                        newDataFilter = dataTable.filter(item => item.IS_KANJI_1 === bool)
                        setDataFilter(newDataFilter)
                        onHandleViewData(newDataFilter[0], 0)
                    }
                } else if (name.toLowerCase() === "furi_1_2") {
                    if (bool !== true && bool !== false) {
                        newDataFilter = dataTable.filter(item => item.IS_FURI_1_2 === true && item.IS_FURI_1_2 === false)
                        setDataFilter(newDataFilter)
                        onHandleViewData(newDataFilter[0], 0)
                    } else {
                        newDataFilter = dataTable.filter(item => item.IS_FURI_1_2 === bool)
                        setDataFilter(newDataFilter)
                        onHandleViewData(newDataFilter[0], 0)
                    }
                }
                else if (name.toLowerCase() === "furi_3") {
                    if (bool !== true && bool !== false) {
                        newDataFilter = dataTable.filter(item => item.IS_FURI_3 === true && item.IS_FURI_3 === false)
                        setDataFilter(newDataFilter)
                        onHandleViewData(newDataFilter[0], 0)
                    } else {
                        newDataFilter = dataTable.filter(item => item.IS_FURI_3 === bool)
                        setDataFilter(newDataFilter)
                        onHandleViewData(newDataFilter[0], 0)
                    }
                }
                else if (name.toLowerCase() === "kanji_2") {
                    if (bool !== true && bool !== false) {
                        newDataFilter = dataTable.filter(item => item.IS_KANJI_2 === true && item.IS_KANJI_2 === false)
                        setDataFilter(newDataFilter)
                        onHandleViewData(newDataFilter[0], 0)
                    } else {
                        newDataFilter = dataTable.filter(item => item.IS_KANJI_2 === bool)
                        setDataFilter(newDataFilter)
                        onHandleViewData(newDataFilter[0], 0)
                    }
                }
                else if (name.toLowerCase() === "kanji_3") {
                    if (bool !== true && bool !== false) {
                        newDataFilter = dataTable.filter(item => item.IS_KANJI_3 === true && item.IS_KANJI_3 === false)
                        setDataFilter(newDataFilter)
                        onHandleViewData(newDataFilter[0], 0)
                    } else {
                        newDataFilter = dataTable.filter(item => item.IS_KANJI_3 === bool)
                        setDataFilter(newDataFilter)
                        onHandleViewData(newDataFilter[0], 0)
                    }
                }
            }
        } else {
            setStatusData("")
            setNameTotal("")
            setSaveNameOption(name.toLowerCase())
            setSaveBoolOption(bool)

            if (name.toLowerCase() === "kanji_1") {
                if (bool !== true && bool !== false) {
                    newDataFilter = dataTable.filter(item => item.IS_KANJI_1 !== true && item.IS_KANJI_1 !== false)
                    setDataFilter(newDataFilter)
                    onHandleViewData(newDataFilter[0], 0)

                } else {
                    newDataFilter = dataTable.filter(item => item.IS_KANJI_1 === bool)
                    setDataFilter(newDataFilter)
                    onHandleViewData(newDataFilter[0], 0)
                }
            } else if (name.toLowerCase() === "furi_1_2") {
                if (bool !== true && bool !== false) {
                    newDataFilter = dataTable.filter(item => item.IS_FURI_1_2 === true && item.IS_FURI_1_2 === false)
                    setDataFilter(newDataFilter)
                    onHandleViewData(newDataFilter[0], 0)
                } else {
                    newDataFilter = dataTable.filter(item => item.IS_FURI_1_2 === bool)
                    setDataFilter(newDataFilter)
                    onHandleViewData(newDataFilter[0], 0)
                }
            }
            else if (name.toLowerCase() === "furi_3") {
                if (bool !== true && bool !== false) {
                    newDataFilter = dataTable.filter(item => item.IS_FURI_3 === true && item.IS_FURI_3 === false)
                    setDataFilter(newDataFilter)
                    onHandleViewData(newDataFilter[0], 0)
                } else {
                    newDataFilter = dataTable.filter(item => item.IS_FURI_3 === bool)
                    setDataFilter(newDataFilter)
                    onHandleViewData(newDataFilter[0], 0)
                }
            }
            else if (name.toLowerCase() === "kanji_2") {
                if (bool !== true && bool !== false) {
                    newDataFilter = dataTable.filter(item => item.IS_KANJI_2 === true && item.IS_KANJI_2 === false)
                    setDataFilter(newDataFilter)
                    onHandleViewData(newDataFilter[0], 0)
                } else {
                    newDataFilter = dataTable.filter(item => item.IS_KANJI_2 === bool)
                    setDataFilter(newDataFilter)
                    onHandleViewData(newDataFilter[0], 0)
                }
            }
            else if (name.toLowerCase() === "kanji_3") {
                if (bool !== true && bool !== false) {
                    newDataFilter = dataTable.filter(item => item.IS_KANJI_3 === true && item.IS_KANJI_3 === false)
                    setDataFilter(newDataFilter)
                    onHandleViewData(newDataFilter[0], 0)
                } else {
                    newDataFilter = dataTable.filter(item => item.IS_KANJI_3 === bool)
                    setDataFilter(newDataFilter)
                    onHandleViewData(newDataFilter[0], 0)
                }
            }
        }
    }



    const onFilterAll = (status) => {
        let newDataFilter = []
        setStatusTotalAcc("")
        setSaveNameOption("")
        setNameTotal("")
        setStatusOnHold('')
        setInputSearch("")
        if (statusData !== "") {
            if (statusData === status) {
                setStatusData("")
                setDataFilter(dataTable)
                onHandleViewData(dataTable[0], 0)
            } else {

                setStatusData(status)
                newDataFilter = dataTable.filter(item => item.STATUS === status)
                setDataFilter(newDataFilter)
                onHandleViewData(newDataFilter[0], 0)
            }
        } else {
            setStatusData(status)
            newDataFilter = dataTable.filter(item => item.STATUS === status)
            setDataFilter(newDataFilter)
            onHandleViewData(newDataFilter[0], 0)
        }
    }

    const onFilterAll2 = (status, name) => {
        let newDataFilter = []
        setStatusTotalAcc("")
        setStatusOnHold('')
        setStatusData("")
        setSaveNameOption('')
        setInputSearch("")
        console.log(status)
        if (statusTotal !== "" && nameTotal !== "") {
            if (statusTotal === status && nameTotal === name) {
                setNameTotal('')
                setDataFilter(dataTable)
                onHandleViewData(dataTable[0], 0)
            }
            else {
                setStatusTotal(status)
                setNameTotal(name)

                if (name === "kanji") {
                    //status = "T" hoặc "F"
                    if (status === "T") {
                        newDataFilter = dataTable.filter(item => item.IS_TOTAL_KANJI === status || item.STATUS === status)

                    } else {
                        newDataFilter = dataTable.filter(item => item.IS_TOTAL_KANJI === status && item.STATUS === status)

                    }


                }
                else if (name === "furi") {
                    if (status === "T") {
                        newDataFilter = dataTable.filter(item => item.IS_TOTAL_KANA === status || item.STATUS === status)
                    } else {
                        newDataFilter = dataTable.filter(item => item.IS_TOTAL_KANA === status && item.STATUS === status)
                    }
                }

                setDataFilter(newDataFilter)
                onHandleViewData(newDataFilter[0], 0)
            }
        } else {
            setStatusTotal(status)
            setNameTotal(name)
            if (name === "kanji") {
                //status = "T" hoặc "F"
                if (status === "T") {
                    newDataFilter = dataTable.filter(item => item.IS_TOTAL_KANJI === status || item.STATUS === status)

                } else {
                    newDataFilter = dataTable.filter(item => item.IS_TOTAL_KANJI === status && item.STATUS === status)

                }
            }
            else if (name === "furi") {
                if (status === "T") {
                    newDataFilter = dataTable.filter(item => item.IS_TOTAL_KANA === status || item.STATUS === status)
                } else {
                    newDataFilter = dataTable.filter(item => item.IS_TOTAL_KANA === status && item.STATUS === status)
                }
            }
            setDataFilter(newDataFilter)
            onHandleViewData(newDataFilter[0], 0)
        }
        console.log(newDataFilter)

    }

    const onHandleViewData = (record, rowIndex) => {
        // setViewFurigana(record.FURIGANA)
        // setViewKanji(record.KANJI)
        if (record !== undefined) {
            setDataBox1(record['01_MBD'].replace(/－/g, "—"))
            setDataBox2(record['02_KANAFULL'].replace(/－/g, "—"))
            setDataBox3(record['03_KANJIFULL'].replace(/－/g, "—"))
            setDataBox4(record['04_KANAGET'].replace(/－/g, "—"))
            setDataBox5(record['05_KANA1_OUT'].replace(/－/g, "—"))
            setDataBox6(record['06_LEN_KANA1'])
            setDataBox7(record['07_KANA2_OUT'].replace(/－/g, "—"))
            setDataBox8(record['08_LEN_KANA2'])
            setDataBox9(record['09_KANA3_OUT'].replace(/－/g, "—"))
            setDataBox10(record['10_LEN_KANA3'])
            setDataBox11(record['11_KANA1'].replace(/－/g, "—"))
            setDataBox12(record['12_KANA2'].replace(/－/g, "—"))
            setDataBox13(record['13_KANA3'].replace(/－/g, "—"))
            setDataBox14(record['14_KANJI1'].replace(/－/g, "—"))
            setDataBox15(record['15_KANJI2'].replace(/－/g, "—"))
            setDataBox16(record['16_KANJI3'].replace(/－/g, "—"))
            setDataBox17(record['17_KANJI1_OUT'].replace(/－/g, "—"))
            setDataBox18(record['18_LEN_KANJI1'])
            setDataBox19(record['19_KANJI2_OUT'].replace(/－/g, "—"))
            setDataBox20(record['20_LEN_KANJI2'])
            setDataBox21(record['21_KANJI3_OUT'].replace(/－/g, "—"))
            setDataBox22(record['22_LEN_KANJI3'])
            setDataBox23(record['23_KANJI1_GET'].replace(/－/g, "—"))
            setIndexHighlight(rowIndex)

            // 14 17
            if (record['IS_KANJI_1'] === false) {
                setColorText14_17("red")
            } else {
                setColorText14_17("black")
            }

            // 15 19
            if (record['IS_KANJI_2'] === false) {
                setColorText15_19("red")
            } else {
                setColorText15_19("black")
            }

            if (record['IS_KANJI_3'] === false) {
                setColorText16_21("red")
            } else {
                setColorText16_21("black")
            }

            // 9 13
            if (record['IS_FURI_3'] === false) {
                setColorText9_13("red")
            } else {
                setColorText9_13("black")
            }

            // 5 11
            // 7 12
            if (record['IS_FURI_1_2'] === true) {
                setColorText5_11('black');
                setColorText7_12("black");
            } else {
                // 5 11
                if (record['05_KANA1_OUT'] !== record['11_KANA1']) {
                    setColorText5_11("red")
                } else {
                    setColorText5_11("black")
                }

                // 7 12
                if (record['07_KANA2_OUT'] !== record['12_KANA2']) {
                    setColorText7_12("red")
                } else {
                    setColorText7_12("black")
                }
            }
        }
    }

    useEffect(() => {
        if (dataTable.length > 0) {
            setViewFurigana(dataTable[0].FURIGANA)
            setViewKanji(dataTable[0].KANJI)
            setDataBox1(dataTable[0]['01_MBD'].replace(/－/g, "—"))
            setDataBox2(dataTable[0]['02_KANAFULL'].replace(/－/g, "—"))
            setDataBox3(dataTable[0]['03_KANJIFULL'].replace(/－/g, "—"))
            setDataBox4(dataTable[0]['04_KANAGET'].replace(/－/g, "—"))
            setDataBox5(dataTable[0]['05_KANA1_OUT'].replace(/－/g, "—"))
            setDataBox6(dataTable[0]['06_LEN_KANA1'])
            setDataBox7(dataTable[0]['07_KANA2_OUT'].replace(/－/g, "—"))
            setDataBox8(dataTable[0]['08_LEN_KANA2'])
            setDataBox9(dataTable[0]['09_KANA3_OUT'].replace(/－/g, "—"))
            setDataBox10(dataTable[0]['10_LEN_KANA3'])
            setDataBox11(dataTable[0]['11_KANA1'].replace(/－/g, "—"))
            setDataBox12(dataTable[0]['12_KANA2'].replace(/－/g, "—"))
            setDataBox13(dataTable[0]['13_KANA3'].replace(/－/g, "—"))
            setDataBox14(dataTable[0]['14_KANJI1'].replace(/－/g, "—"))
            setDataBox15(dataTable[0]['15_KANJI2'].replace(/－/g, "—"))
            setDataBox16(dataTable[0]['16_KANJI3'].replace(/－/g, "—"))
            setDataBox17(dataTable[0]['17_KANJI1_OUT'].replace(/－/g, "—"))
            setDataBox18(dataTable[0]['18_LEN_KANJI1'])
            setDataBox19(dataTable[0]['19_KANJI2_OUT'].replace(/－/g, "—"))
            setDataBox20(dataTable[0]['20_LEN_KANJI2'])
            setDataBox21(dataTable[0]['21_KANJI3_OUT'].replace(/－/g, "—"))
            setDataBox22(dataTable[0]['22_LEN_KANJI3'])
            setDataBox23(dataTable[0]['23_KANJI1_GET'].replace(/－/g, "—"))
            setIndexHighlight(0)
        }
    }, [dataTable]);

    const downloadFile = () => {
        const fnFileDownload = require('js-file-download')
        axios.post(`${localhost}api/download_file/`, {
            name_file_import: valueNameNCC,
            time_import: timeImport,
        }, { responseType: 'blob' }).then(res => {
            fnFileDownload(res.data, valueNameNCC);
        }).catch(err => { console.log(err) })
    }

    const [inputSearch, setInputSearch] = useState("")
    const [dataSearch, setDataSearch] = useState([])

    const onSearch = (e) => {
        setInputSearch(e.target.value.toUpperCase())
        const keyword = e.target.value.toUpperCase();
        const valueSS = dataFilter
            .filter(
                (value) =>
                    value["01_MBD"].toUpperCase().includes(keyword) ||
                    value["KANJI_1_RESULT__KANJI_1_OUTPUT"].toUpperCase().includes(keyword) ||
                    value["KANJI_2_RESULT__KANJI_2_OUTPUT"].toUpperCase().includes(keyword) ||
                    value['KANJI_3_RESULT__KANJI_3_OUTPUT'].toUpperCase().includes(keyword) ||
                    value['FURI_1_2_RESULT__FURI_1_2_OUTPUT'].toUpperCase().includes(keyword) ||
                    value['FURI_3_OUTPUT__FURI_3_RESULT'].toUpperCase().includes(keyword) ||
                    value['RULES'].toUpperCase().includes(keyword) || 
                    value['STT'].toUpperCase().includes(keyword)
            )
            .map((item) => {
                return item;
            });
        setDataSearch(valueSS);
    }

    const onFilterWarning = (status) => {
        let newDataFilter = []
        setStatusTotalAcc("")
        setSaveNameOption("")
        setNameTotal("")
        setStatusData("")
        setInputSearch("")
        if (statusOnHold !== "") {
            if (statusOnHold === status) {
                setStatusOnHold("")
                setDataFilter(dataTable)
                onHandleViewData(dataTable[0], 0)
            } else {
                newDataFilter = dataTable.filter(item => item.STATUS === status)
                setDataFilter(newDataFilter)
                setStatusOnHold(status)
                onHandleViewData(newDataFilter[0], 0)
            }
        } else {
            newDataFilter = dataTable.filter(item => item.STATUS === status)
            setDataFilter(newDataFilter)
            setStatusOnHold(status)
            onHandleViewData(newDataFilter[0], 0)
        }
    }

    const [statusTotalAcc, setStatusTotalAcc] = useState('')

    const onFilterTotalAcc = (status) => {
        setSaveNameOption("")
        setNameTotal("")
        setStatusData("")
        setInputSearch("")
        setStatusOnHold('')

        let newDataFilter = []
        let newDataWarning = []
        let newDataOnHold = []
        let newData = []
        let lastData = []
        if (statusTotalAcc !== "") {
            if (statusTotalAcc === status) {
                setStatusTotalAcc("")
                setDataFilter(dataTable)
                onHandleViewData(dataTable[0], 0)
            } else {
                newDataOnHold = dataTable.filter(item => item.STATUS === "R")
                newDataWarning = dataTable.filter(item => item.STATUS === "W")
                newData = newDataOnHold.concat(newDataWarning)
                if (status === "TKJ") {
                    newDataFilter = dataTable.filter(item => item.IS_TOTAL_KANJI === "T")
                    lastData = newData.concat(newDataFilter)
                } else if (status === "TKN") {
                    newDataFilter = dataTable.filter(item => item.IS_TOTAL_KANA === "T")
                    lastData = newData.concat(newDataFilter)
                } else if (status === "TA") {
                    newDataFilter = dataTable.filter(item => item.IS_TOTAL_KANA === "T" && item.IS_TOTAL_KANJI === "T")
                    lastData = newData.concat(newDataFilter)
                }
                setDataFilter(lastData)
                setStatusTotalAcc(status)
                onHandleViewData(newDataFilter[0], 0)
            }
        } else {
            newDataOnHold = dataTable.filter(item => item.STATUS === "R")
            newDataWarning = dataTable.filter(item => item.STATUS === "W")
            newData = newDataOnHold.concat(newDataWarning)
            if (status === "TKJ") {
                newDataFilter = dataTable.filter(item => item.IS_TOTAL_KANJI === "T")
                lastData = newData.concat(newDataFilter)
            } else if (status === "TKN") {
                newDataFilter = dataTable.filter(item => item.IS_TOTAL_KANA === "T")
                lastData = newData.concat(newDataFilter)
            } else if (status === "TA") {
                newDataFilter = dataTable.filter(item => item.IS_TOTAL_KANA === "T" && item.IS_TOTAL_KANJI === "T")
                lastData = newData.concat(newDataFilter)
            }
            setDataFilter(lastData)
            setStatusTotalAcc(status)
            onHandleViewData(newDataFilter[0], 0)
        }
    }

    return (
        <>
            <div className='container'>
                <img className='custom-img-logo' src={Logo}></img>
                <div style={{ height: "15vh" }}>
                    <div className='center-display'>
                        <div className='class-title'>
                            <span style={{ margin: "5px", fontSize: 30, fontWeight: "bold" }}>KADO - iPA</span>
                        </div>
                        <input
                            ref={fileInputRef}
                            accept=".xlsx,.xls"
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                            name="myFile1"
                            id="myFile1"
                        />
                        <div className='center-display' style={{ width: "100%" }}>
                            <Row className='custom-row'>
                                <Col span={1} style={{ fontSize: 16 }}><span className='text-label'>FILE: </span></Col>
                                <Col span={18} style={{ marginLeft: "1%" }}>
                                    <Input
                                        size="middle"
                                        placeholder="Upload File"
                                        type="text"
                                        readOnly
                                        key={'file'}

                                        onClick={() => {
                                            // Khi bạn nhấn vào Input, kích hoạt sự kiện click của nút
                                            const uploadButton = document.getElementById("uploadButton");
                                            uploadButton.click();
                                        }}

                                        suffix={
                                            <div style={{ display: "flex" }}>
                                                <Button
                                                    style={
                                                        screenWindown1540px === true
                                                            ? buttonUploadFileLapTop
                                                            : buttonUploadFile
                                                    }
                                                    htmlFor="myFile1"
                                                    component="label"
                                                    size="small"
                                                    id='uploadButton'
                                                >
                                                    <UploadOutlined style={{ fontSize: 22 }} />
                                                </Button>
                                            </div>
                                        }
                                        value={valueNameNCC ? valueNameNCC : null}
                                        className="inputTTDN"
                                    />
                                </Col>
                                <Col span={2} style={{ marginLeft: "1%" }}>
                                    <button class="button-82-pushable" role="button" onClick={() => onStart()} disabled={loadingButton}>
                                        <span class="button-82-shadow"></span>
                                        <span class="button-82-edge" style={loadingButton === true ? { background: "none" } : null}></span>
                                        <span class="button-82-front text" style={loadingButton === true ? { background: "#c0c1c3" } : null}>
                                            START
                                        </span>
                                    </button>
                                </Col>
                            </Row>
                            <p style={{ margin: "0px 0px 8px 0px", fontSize: 13 }}>Download template <a href={`${localhost}media/Template.xlsx`} download style={{ color: "blue" }}>here</a></p>
                        </div>
                    </div>
                </div>

                <div className='center-display' style={{ heigh: "35vh", rowGap: "0.3ch" }}>

                    <div className='div-new-title'>
                        <div className='new-title'>
                            申込住所
                        </div>
                        <div className='custom-info' style={{ padding: "0.5% 0px" }}>
                            <Col span={22}>
                                <p>&nbsp; {dataBox1}</p>
                            </Col>
                            <Col span={22}>
                                <p>&nbsp; {dataBox2}</p>

                            </Col>
                            <Col span={22}>
                                <p>&nbsp; {dataBox3}</p>
                            </Col>
                        </div>
                    </div>

                    <div className='div-new-title'>
                        <div className='new-title'>
                            住所修正カナ
                        </div>
                        <div className='custom-info'>
                            <div style={{ padding: "0.2%" }}>
                                <div style={{ display: "flex", width: "100%" }}>

                                    <div style={{ width: "6%" }}>
                                        <label for="box-1">&nbsp;</label>
                                        <Input style={{ padding: "0px" }} value={"RESULT:"} bordered={false}></Input>
                                        <Input style={{ padding: "0px" }} value={"OUTPUT:"} bordered={false}></Input>
                                    </div>
                                    <Row className='custom-label' style={{ width: "100%" }}>
                                        <Col span={8}>

                                            <label style={{ float: "right" }} for="box-3">{dataBox6} 文字</label>

                                            <Input id='box-1' style={{ color: colorText5_11 }} readOnly value={dataBox11 && dataBox11}>
                                            </Input>

                                            <Input style={{ padding: "0px", fontSize: 14 }} readOnly value={dataBox5} bordered={false}></Input>
                                        </Col>

                                        <Col span={8}>
                                            <label style={{ float: "right" }} for="box-3">{dataBox8} 文字</label>

                                            <Input id='box-2' style={{ color: colorText7_12 }} readOnly value={dataBox12 && dataBox12}>
                                            </Input>

                                            <Input style={{ padding: "0px", fontSize: 14 }} readOnly value={dataBox7} bordered={false}></Input>
                                        </Col>

                                        <Col span={8}>

                                            <label style={{ float: "right" }} for="box-3">{dataBox10} 文字</label>

                                            <Input id='box-3' style={{ color: colorText9_13 }} readOnly value={dataBox13 && dataBox13}>
                                            </Input>

                                            <Input style={{ padding: "0px", fontSize: 14 }} readOnly value={dataBox9} bordered={false}></Input>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='div-new-title'>
                        <div className='new-title'>
                            住所修正
                        </div>

                        <div className='custom-info' >
                            <div style={{ padding: "0.2%" }}>
                                <div style={{ display: "flex", width: "100%" }}>
                                    <div style={{ width: "6%" }}>
                                        <label for="box-1">&nbsp;</label>
                                        <Input style={{ padding: "0px" }} value={"RESULT:"} bordered={false}></Input>
                                        <Input style={{ padding: "0px" }} value={"OUTPUT:"} bordered={false}></Input>
                                    </div>

                                    <Row className='custom-label' style={{ width: "100%" }}>
                                        <Col span={8}>
                                            <label style={{ float: "right" }} for="box-3">{dataBox18} 文字</label>
                                            <Input id='box-1' style={{ color: colorText14_17 }} readOnly value={dataBox14 && dataBox14}>
                                            </Input>
                                            <Input style={{ padding: "0px", fontSize: 14 }} readOnly value={dataBox17} bordered={false}></Input>

                                        </Col>
                                        <Col span={8}>

                                            <label style={{ float: "right" }} for="box-3">{dataBox20} 文字</label>
                                            <Input id='box-2' style={{ color: colorText15_19 }} readOnly value={dataBox15 && dataBox15}>
                                            </Input>
                                            <Input style={{ padding: "0px", fontSize: 14 }} readOnly value={dataBox19} bordered={false}></Input>

                                        </Col>
                                        <Col span={8}>
                                            <label style={{ float: "right" }} for="box-3">{dataBox22} 文字</label>
                                            <Input id='box-3' style={{ color: colorText16_21 }} readOnly value={dataBox16 && dataBox16}>
                                            </Input>
                                            <Input style={{ padding: "0px", fontSize: 14 }} readOnly value={dataBox21} bordered={false}></Input>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div style={{ heigh: "30vh" }}>
                    <div className='size-table'>
                        <div style={{ display: "flex", width: "100%" }}>
                            <div style={{ display: "flex", alignItems: "flex-end", width: "20%" }}>
                                {/* <Input style={{ width: "100%" }}></Input> */}
                                <Search style={{ width: "100%" }} value={inputSearch} placeholder="input search text" onChange={onSearch} />

                            </div>
                            <div style={{ marginLeft: "7%", width: "80%" }}>
                                <div className='table-filter'>
                                    <table border="1">
                                        <tr style={{ backgroundColor: "#FED5B2" }}>
                                            <th>Total</th>
                                            <th style={{ width: 90 }}>Test for</th>
                                            <th colSpan={2}>On hold</th>
                                            <th colSpan={2}>Return</th>
                                            <th colSpan={2}>Pass OK</th>
                                            <th colSpan={2}>Pass NG</th>
                                            <th colSpan={2}>Total Accuracy</th>
                                        </tr>
                                        <tr>
                                            <td rowSpan={3}>{total}</td>
                                            <td>Kanji address</td>
                                            <td rowSpan={3} onClick={() => onFilterWarning('R')}>
                                                <span className='bool-false-infor' style={{ color: statusOnHold === "R" ? "green" : "blue" }}>{countOnHold}</span>
                                            </td>
                                            <td rowSpan={3} onClick={() => onFilterWarning('R')}>
                                                <span className='bool-false-infor' style={{ color: statusOnHold === "R" ? "green" : "blue" }}>({percentCountOnHold}%)</span>
                                            </td>

                                            <td rowSpan={3} onClick={() => onFilterWarning('W')}>
                                                <span className='bool-false-infor' style={{ color: statusOnHold === "W" ? "green" : "blue" }}>{totalWarning}</span>
                                            </td>
                                            <td rowSpan={3} onClick={() => onFilterWarning('W')}>
                                                <span className='bool-false-infor' style={{ color: statusOnHold === "W" ? "green" : "blue" }}>({percentTotalWarning}%)</span>
                                            </td>




                                            <td onClick={() => onFilterAll2('T', 'kanji')}>
                                                <span className='bool-false-infor' style={{ color: nameTotal === "kanji" && statusTotal === "T" ? "green" : "blue" }}>{totalKanji}</span>
                                            </td>
                                            <td onClick={() => onFilterAll2('T', 'kanji')}>
                                                <span className='bool-false-infor' style={{ color: nameTotal === "kanji" && statusTotal === "T" ? "green" : "blue" }}>({percentTrueTotalKJ}%)</span>
                                            </td>

                                            <td onClick={() => onFilterAll2('F', 'kanji')}>
                                                <span className='bool-false-infor' style={{ color: nameTotal === "kanji" && statusTotal === "F" ? "green" : "red" }}>
                                                    {countFalseKanji}
                                                </span>
                                            </td>
                                            <td onClick={() => onFilterAll2('F', 'kanji')}>
                                                <span className='bool-false-infor' style={{ color: nameTotal === "kanji" && statusTotal === "F" ? "green" : "red" }}>
                                                    ({percentFalseTotalKJ}%)
                                                </span>
                                            </td>

                                            {/* Total Acc */}

                                            <td onClick={() => onFilterTotalAcc('TKJ')}>
                                                <span className='bool-false-infor' style={{ color: statusTotalAcc === "TKJ" ? "green" : "blue" }}>
                                                    {totalAccuracyKanji}
                                                </span>
                                            </td>
                                            <td onClick={() => onFilterTotalAcc('TKJ')}>
                                                <span className='bool-false-infor' style={{ color: statusTotalAcc === "TKJ" ? "green" : "blue" }}>
                                                    ({percentTotalAccuracyKanji}%)
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Furi address</td>
                                            {/* <td>0</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0</td> */}

                                            <td onClick={() => onFilterAll2('T', 'furi')}>
                                                <span className='bool-false-infor' style={{ color: nameTotal === "furi" && statusTotal === "T" ? "green" : "blue" }}>{totalKana}</span>
                                            </td>
                                            <td onClick={() => onFilterAll2('T', 'furi')}>
                                                <span className='bool-false-infor' style={{ color: nameTotal === "furi" && statusTotal === "T" ? "green" : "blue" }}>({percentTrueTotalKN}%)</span>
                                            </td>

                                            <td onClick={() => onFilterAll2('F', 'furi')}>
                                                <span className='bool-false-infor' style={{ color: nameTotal === "furi" && statusTotal === "F" ? "green" : "red" }}>
                                                    {countFalseKana}
                                                </span>
                                            </td>
                                            <td onClick={() => onFilterAll2('F', 'furi')}>
                                                <span className='bool-false-infor' style={{ color: nameTotal === "furi" && statusTotal === "F" ? "green" : "red" }}>
                                                    ({percentFalseTotalKN}%)
                                                </span>
                                            </td>

                                            {/* Total Acc */}

                                            <td onClick={() => onFilterTotalAcc('TKN')}>
                                                <span className='bool-false-infor' style={{ color: statusTotalAcc === "TKN" ? "green" : "blue" }}>
                                                    {totalAccuracyKana}
                                                </span>
                                            </td>
                                            <td onClick={() => onFilterTotalAcc('TKN')}>
                                                <span className='bool-false-infor' style={{ color: statusTotalAcc === "TKN" ? "green" : "blue" }}>
                                                    ({percentTotalAccuracyKana}%)
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Full address</td>

                                            <td onClick={() => onFilterAll('T')}>
                                                <span className='bool-false-infor' style={{ color: statusData === "T" ? "green" : "blue" }}>{totalTrue}</span>
                                            </td>
                                            <td onClick={() => onFilterAll('T')}>
                                                <span className='bool-false-infor' style={{ color: statusData === "T" ? "green" : "blue" }}>({percentTotalTrue}%)</span>
                                            </td>

                                            <td onClick={() => onFilterAll('F')}>
                                                <span className='bool-false-infor' style={{ color: statusData === "F" ? "green" : "red" }}>{totalFalse}</span>
                                            </td>
                                            <td onClick={() => onFilterAll('F')}>
                                                <span className='bool-false-infor' style={{ color: statusData === "F" ? "green" : "red" }}>({percentTotalFalse}%)</span>
                                            </td>

                                            {/* Total Acc */}

                                            <td onClick={() => onFilterTotalAcc('TA')}>
                                                <span className='bool-false-infor' style={{ color: statusTotalAcc === "TA" ? "green" : "blue" }}>
                                                    {totalAccuracyAll}
                                                </span>
                                            </td>
                                            <td onClick={() => onFilterTotalAcc('TA')}>
                                                <span className='bool-false-infor' style={{ color: statusTotalAcc === "TA" ? "green" : "blue" }}>
                                                    ({percentTotalAccuracyAll}%)
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Table
                            dataSource={inputSearch !== "" ? dataSearch : dataFilter}
                            className='custom-table'
                            columns={columns}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        onHandleViewData(record, rowIndex)
                                    },
                                };
                            }}

                            // pagination= {false}

                            pagination={{
                                position: ["bottomCenter"],
                                defaultPageSize: 20,
                            }}
                            rowClassName={(record, index) =>
                                index === indexHighlight ? "table-row-light" : "table-row-dark"
                            }
                            scroll={dataTable.length > 0 ? {
                                y: window.screen.height < 900 ? "23vh" : "31vh",
                            } : null}
                            size='small'
                            loading={loadingButton}
                            bordered
                        />
                    </div>


                    {dataTable.length > 0 ?
                        <Tooltip placement='topRight' title="Tải dữ liệu"><IconButton style={{
                            position: "absolute",
                            right: "1%",
                            bottom: "7%",
                            // background: "rgb(25 118 210 / 93%)",
                        }} onClick={downloadFile}><DownloadOutlined style={{ fontSize: checkScreen800 ? 32 : 37, color: "black" }} /></IconButton></Tooltip>
                        : null}
                    <ModalProgress subID={subID} isOpenProgress={isOpenProgress} percentProcessing={percentProcessing} setPercentProcessing={setPercentProcessing} setIsOpenProgress={setIsOpenProgress} timeHandleAPI={timeHandleAPI} checkSuccessAPI={checkSuccessAPI} setCheckSuccessAPI={setCheckSuccessAPI} />
                </div>
            </div>
        </>
    )
})

const ModalProgress = ({ subID, isOpenProgress, percentProcessing, setPercentProcessing, setIsOpenProgress, timeHandleAPI, checkSuccessAPI, setCheckSuccessAPI }) => {
    const [hourTime, setHourTime] = useState(0)
    const [minuteTime, setMinuteTime] = useState(0)
    const [secondTime, setSecondTime] = useState(0)

    useEffect(() => {
        if (checkSuccessAPI === true) {
            if (timeHandleAPI >= 60) {
                console.log(timeHandleAPI)
                if (Math.floor(timeHandleAPI / 60) / 60 < 10) {
                    if ((Math.floor(timeHandleAPI / 60) / 60) < 10) {
                        setHourTime("0" + (Math.floor(timeHandleAPI / 60) / 60))
                    } else {
                        setHourTime((Math.floor(timeHandleAPI / 60) / 60))
                    }
                } else {
                    setHourTime("00")
                }

                if (Math.floor(timeHandleAPI / 60) < 10) {
                    setMinuteTime("0" + Math.floor(timeHandleAPI / 60))
                } else {
                    setMinuteTime(Math.floor(timeHandleAPI / 60))
                }

                if (Math.round(timeHandleAPI % 60) < 10) {
                    setSecondTime("0" + Math.round(timeHandleAPI % 60))
                } else {
                    setSecondTime(Math.round(timeHandleAPI % 60))
                }
            } else if (timeHandleAPI < 60) {
                setMinuteTime("00")
                setHourTime("00")
                if (Math.round(timeHandleAPI) < 10) {
                    setSecondTime("0" + Math.round(timeHandleAPI))
                } else {
                    setSecondTime(Math.round(timeHandleAPI))
                }
            }
        }
    }, [checkSuccessAPI]);

    const onConFirm = () => {
        setPercentProcessing(0)
        setIsOpenProgress(false)
        setCheckSuccessAPI(false)
        setHourTime(0)
        setMinuteTime(0)
        setSecondTime(0)
    }

    return (<>
        <Modal
            open={isOpenProgress}
            // onCancel={handleCancel}
            footer={null}
            width={600}
            closable={false}
            style={{ marginTop: "2%" }}

        // cancelText={null}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <p style={{ fontWeight: "bold", fontSize: 18 }}>Processing</p>
                <Progress
                    percent={percentProcessing}
                    status="active"
                    style={{ width: "100%" }}
                />

            </div>


            {checkSuccessAPI === true ? <h3>Processing time: <span style={{ fontSize: 24, color: "#0064ffde", marginLeft: "3%" }}>{hourTime} : {minuteTime} : {secondTime}</span></h3> : <h3>Processing time: </h3>}
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                {/* <Button>123</Button> */}
                {checkSuccessAPI === true ? <button class="button-3" role="button" onClick={onConFirm}>OK</button> : null}
            </div>
        </Modal>
    </>)
}

export default NewKado;
